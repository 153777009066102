import * as React from "react";

const NewsletterLoading = (props) => (
  <svg width={21} height={21} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      opacity={0.3}
    >
      <g id="load" transform="translate(0 1)" stroke="#FFF" strokeWidth={2}>
        <path
          d="M19.285 1.991 13.6 17.822a1 1 0 0 1-1.832.116l-3.26-6.4L2.044 8.2a1 1 0 0 1 .123-1.83L18.007.712a1 1 0 0 1 1.278 1.28Z"
          id="Path-12"
        />
        <path
          id="Path-13"
          strokeLinecap="round"
          transform="rotate(180 13.448 6.538)"
          d="m8.895 11.077 9.492-9.539"
        />
      </g>
    </g>
    <g stroke="#FFF" strokeWidth={2} fill="none" fillRule="evenodd">
      <path
        d="M19.285 1.991 13.6 17.822a1 1 0 0 1-1.832.116l-3.26-6.4L2.044 8.2a1 1 0 0 1 .123-1.83L18.007.712a1 1 0 0 1 1.278 1.28Z"
        style={{
          animation:
            "jiMVaEuO_draw_0 1000ms ease-in-out 0ms infinite,jiMVaEuO_fade 1000ms linear 0ms infinite",
        }}
        strokeDasharray="56 58"
        strokeDashoffset={57}
        transform="translate(0 1)"
      />
      <path
        strokeLinecap="round"
        transform="rotate(180 13.448 7.038)"
        d="m8.895 11.077 9.492-9.539"
        style={{
          animation:
            "jiMVaEuO_draw_1 1000ms ease-in-out 0ms infinite,jiMVaEuO_fade 1000ms linear 0ms infinite",
        }}
        strokeDasharray="14 16"
        strokeDashoffset={15}
      />
    </g>
    <style data-made-with="vivus-instant">
      {
        "@keyframes jiMVaEuO_fade{0%,90%{stroke-opacity:1}}@keyframes jiMVaEuO_draw_0{10%{stroke-dashoffset:57}65.41666666666667%{stroke-dashoffset:0}}@keyframes jiMVaEuO_draw_1{65.41666666666667%{stroke-dashoffset:15}80%{stroke-dashoffset:0}}"
      }
    </style>
  </svg>
);

export default NewsletterLoading;
