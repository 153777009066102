import React from "react";
import { Mail } from "react-feather";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "next-share";

interface ISocialShareButtonsProps {
  slug: string;
}

function SocialShareButtons({ slug }: ISocialShareButtonsProps): JSX.Element {
  const url = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/blog/${slug}/`;
  return (
    <div className="flex flex-col justify-start items-start my-12">
      <div className="h6 mb-4">Share this insight:</div>
      <div className="flex justify-start items-center gap-x-4">
        <FacebookShareButton url={url}>
          <div className="flex justify-center items-center h-12 w-12 border-2 border-blue hover:border-blue-light rounded-full">
            <img src="/images/facebook.svg" alt="facebook icon" />
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <div className="flex justify-center items-center h-12 w-12 border-2 border-green hover:border-green-light rounded-full">
            <img src="/images/twitter.svg" alt="twitter icon" />
          </div>
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
          <div className="flex justify-center items-center h-12 w-12 border-2 border-yellow hover:border-yellow-light rounded-full">
            <img src="/images/linkedin.svg" alt="linkedin icon" />
          </div>
        </LinkedinShareButton>
        <EmailShareButton url={url}>
          <div className="flex justify-center items-center h-12 w-12 border-2 border-red hover:border-red-light rounded-full">
            <Mail className="w-6 h-6 text-red" />
          </div>
        </EmailShareButton>
      </div>
    </div>
  );
}

export default SocialShareButtons;
