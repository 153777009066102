import { Work as WorkType } from "client";
import dynamic from "next/dynamic";
import { useWindowSize } from "hooks";
const DynamicDesktopWorkSlider = dynamic(() => import("./DesktopWorkSlider"), {
  loading: () => <div>Loading...</div>,
});

const DynamicMobileWorkSlider = dynamic(() => import("./MobileWorkSlider"), {
  loading: () => <div>Loading...</div>,
});

interface IWorkSliderProps {
  works: WorkType[];
}

function WorkSlider({ works }: IWorkSliderProps): JSX.Element {
  const { width } = useWindowSize();
  return width > 1100 ? (
    <DynamicDesktopWorkSlider works={works} />
  ) : (
    <DynamicMobileWorkSlider works={works} />
  );
}

export default WorkSlider;
