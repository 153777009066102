import { Send } from "react-feather";
import NewsletterLoading from "../svgs/NewsletterLoading";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import { INewsletterCTAFormValues } from "../../../hooks/useSubscribeToNewsletter";

interface INewsletterCTAProps {
  isSubscribed: boolean;
  subscribeOnSubmit: (
    values: INewsletterCTAFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  message: string;
  isError: string;
}

function NewsletterCTA({
  isSubscribed,
  subscribeOnSubmit,
  message,
  isError,
}: INewsletterCTAProps) {
  return (
    <div className="flex flex-col gap-4 md:gap-8 w-full">
      <div className="flex flex-col gap-2 md:gap-4">
        {!isSubscribed && !isError ? (
          <>
            <h4 className="text-xl">Keep up to date</h4>
            <p className="text-black text-sm">Subscribe to out newsletter</p>
          </>
        ) : (
          <>
            <h4 className="text-xl">{isError ? isError : "Subscribed!"}</h4>
            <p className="text-black text-sm">{message}</p>
          </>
        )}
      </div>
      {!isSubscribed && !isError ? (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Invalid email address.")
              .required("Required"),
          })}
          onSubmit={(
            values: INewsletterCTAFormValues,
            { setSubmitting }: FormikHelpers<INewsletterCTAFormValues>
          ) => {
            subscribeOnSubmit(values, setSubmitting);
            setSubmitting(true);
          }}
        >
          {(formik) => {
            return (
              <form className="w-full max-w-lg" onSubmit={formik.handleSubmit}>
                <div className="relative h-12 w-full md:w-full bg-[#F3F5F7] rounded-md text-sm">
                  <Field
                    className={`bg-inherit pl-4 text-black placeholder-gray w-full h-full outline-none pr-12 rounded-md ${
                      formik?.touched.email && formik.errors.email
                        ? "outline-red-500"
                        : ""
                    }`}
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <button
                    className="w-9 h-9 absolute top-1.5 right-2 bg-[#272727] flex justify-center items-center rounded-md disabled:opacity-70"
                    type="submit"
                    disabled={
                      formik.isSubmitting ||
                      (formik.isValid &&
                      !formik.errors.email &&
                      formik.values.email !== ""
                        ? false
                        : true)
                    }
                  >
                    {!formik.isSubmitting && (
                      <Send className="w-5 h-5" color="white" />
                    )}
                    {formik.isSubmitting && (
                      <div className="bg-black w-5 h-5">
                        <NewsletterLoading />
                      </div>
                    )}
                  </button>
                  {formik?.touched.email && formik.errors.email ? (
                    <p className="text-sm mt-2 text-red-500">
                      Please provide a valid email address.
                    </p>
                  ) : null}
                </div>
              </form>
            );
          }}
        </Formik>
      ) : null}
    </div>
  );
}

export default NewsletterCTA;
