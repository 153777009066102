interface IGenericDivider {
  className?: string;
}

function GenericDivider({ className }: IGenericDivider) {
  return (
    <div className={`w-full border-t border-gray-300 z-10 ${className}`} />
  );
}

export default GenericDivider;
