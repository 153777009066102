var slugify = require("slugify");

const useHeadlessSeries = () => {
  const options = {
    lower: true, // convert to lower case, defaults to `false`
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
    strict: true, // strip special characters except replacement, defaults to `false`
  };
  return [
    {
      title: "Chapter 1: Introduction to Headless",
      shortTitle: "Introduction",
      slug: slugify("Introduction to Headless", options),
      link: "/blog/introduction-to-headless",
      icon: "/images/introduction.svg",
      content:
        "Before you learn about the benefits of headless, let’s dive into the basics of headless architectures.",
    },
    {
      title: "Chapter 2: Traditional vs. Headless",
      shortTitle: "Traditional vs. Headless",
      slug: slugify("Traditional vs. Headless", options),
      link: "/blog/traditional-vs-headless",
      icon: "/images/versus.svg",
      content:
        "Let’s compare traditional architectures like WordPress or Wix vs headless architectures and learn about key differences.",
    },
    {
      title: "Chapter 3: Benefits of Headless",
      shortTitle: "Benefits of Headless",
      slug: slugify("Benefits of Headless", options),
      link: "/blog/benefits-of-headless",
      icon: "/images/benefits.svg",
      content:
        "Take a closer look at the benefits of headless and what they mean for you.",
    },
    {
      title: "Chapter 4: Headless Use Cases",
      shortTitle: "Headless Use Cases",
      slug: slugify("Headless Use Cases", options),
      link: "/blog/headless-use-cases",
      icon: "/images/use-case.svg",
      content: `Let’s dive into where headless makes sense and some of the best use cases.`,
    },
    {
      title: "Chapter 5: Transitioning to Headless",
      shortTitle: "Transitioning to Headless",
      slug: slugify("Transitioning to Headless", options),
      link: "/blog/transitioning-to-headless",
      icon: "/images/transition.svg",
      content:
        "Transitioning to a headless architecture doesn’t haven’t to be painful. Come along with us to learn best practices for transitioning to headless.",
    },
    {
      title: "Chapter 6: Headless SEO",
      shortTitle: "Headless SEO",
      slug: slugify("Headless SEO", options),
      link: "/blog/headless-seo",
      icon: "/images/seo.svg",
      content: "Headless SEO is really just SEO.",
    },
    {
      title: "Chapter 7: Headless AI",
      shortTitle: "Headless AI",
      slug: slugify("Headless AI", options),
      link: "/blog/headless-ai",
      icon: "/images/ai.svg",
      content:
        "Headless AI is a powerful combination. Learn how to leverage AI in your headless architecture.",
    },
  ];
};

export default useHeadlessSeries;
