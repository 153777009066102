import React from "react";
import { useState, useEffect } from "react";
import clsx from "clsx";
import Link from "next/link";
import { useHeadlessSeries } from "hooks";
import SeriesBannerMobile from "./Series.mobile.banner";

function SeriesBanner({ post }): JSX.Element {
  const [isSticky, setSticky] = useState(false);
  const chapters = useHeadlessSeries();
  const handleScroll = () => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 70) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={clsx(
        isSticky && "currently__reading--stuck sticky top-0 shadow-lg z-10"
      )}
    >
      <SeriesBannerMobile isSticky={isSticky} chapters={chapters} post={post} />
      <div className="hidden lg:block w-full py-4 mb-8 mx-auto bg-blue-dark text-white">
        <div className="section-container flex justify-between items-start md:items-center w-full px-4 sm:px-6 2xl:px-0 mx-auto">
          <Link href="/headless" className="bold">
            <a>Headless Guide: </a>
          </Link>
          {chapters &&
            chapters.map((chapter, index) => (
              <Link href={chapter?.link} key={chapter?.link}>
                <a
                  className={clsx(
                    "flex flex-col md:flex-row items-start md:items-center group",
                    post?.slug === chapter?.slug && "active"
                  )}
                >
                  <div>
                    <div className="font-light text-xs mr-3 justify-center align-middle">
                      <span className="series-chapter mr-2 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-white transition group-hover:bg-white float-left">
                        <span className="series-chapter-text text-white group-hover:text-blue-dark">
                          {index + 1}
                        </span>
                      </span>
                      <span className="series-chapter-title h-6 flex items-center">
                        {chapter?.shortTitle}
                      </span>
                    </div>
                  </div>
                </a>
              </Link>
            ))}
        </div>
      </div>
      <style jsx>{`
        .active .series-chapter {
          background: white;
          border: white;
        }
        .active .series-chapter-text {
          background: white;
          border: white;
          color: var(--color-blue-dark);
        }
      `}</style>
    </div>
  );
}

export default SeriesBanner;
