import React from "react";
import { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { ArrowRight } from "react-feather";
import Link from "next/link";

function CurrentlyReadingBanner({ post }): JSX.Element {
  const [scrollY, setScrollY] = useState(0);

  const onScroll = useCallback(() => {
    setScrollY(window.screenY);
  }, []);

  useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });
    // remove event on unmount to prevent a memory leak
    () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const nextTitle = post?.next?.title();
  const nextSlug = post?.next?.slug;

  return (
    <div
      className={clsx(
        scrollY > 70 &&
          "currently__reading--stuck sticky top-[18px] md:top-[26px] shadow-lg transform translate-y-[4.5rem] md:translate-y-20 transition-transform ease-out z-10"
      )}
    >
      <div className="bg-slate-100 w-full py-4 mb-8 mx-auto">
        <div className="section-container flex justify-between items-start md:items-center w-full px-4 sm:px-6 2xl:px-0 mx-auto">
          <div className="flex flex-col md:flex-row items-start md:items-center w-1/2 md:w-auto">
            <div className="font-bold text-sm mr-3">Currently reading:</div>
            <div className="font-light text-sm">{post?.title()}</div>
          </div>
          {nextSlug && (
            <Link href={`/blog/${nextSlug}/`}>
              <a className="flex flex-col md:flex-row items-start md:items-center hover:border-b border-black">
                <div className="font-bold text-sm mr-3">Up next:</div>
                <div className="flex items-center">
                  <div className="font-light text-sm mr-3">{nextTitle}</div>
                  <ArrowRight className="w-4 h-4 text-black" />
                </div>
              </a>
            </Link>
          )}
        </div>
      </div>
      <style jsx>{`
        .currently__reading--stuck {
          top: 8px;
        }
      `}</style>
    </div>
  );
}

export default CurrentlyReadingBanner;
