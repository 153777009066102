import React, { useEffect, useState } from "react";
import { Category } from "client";
import Link from "next/link";
import {
  Briefcase,
  HelpCircle,
  Users,
  Code,
  Check,
  Layers,
} from "react-feather";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

interface Props {
  categories: Category[] | undefined;
  activeCategory: string;
}

function BlogCategories({
  categories,
  activeCategory = "",
}: Props): JSX.Element {
  return (
    <div className="w-full md:pl-16">
      <div className="h6 text-black mb-4">Blog Categories</div>
      <ul className="border-b border-slate-300">
        {categories.map((category) => {
          const categoryIcon =
            category.name == "Business" ? (
              <Briefcase className="w-6 h-6 text-green-light" />
            ) : category.name == "Tech" ? (
              <Code className="w-6 h-6 text-red" />
            ) : category.name == "Community" ? (
              <Users className="w-6 h-6 text-yellow-light" />
            ) : (
              <HelpCircle className="w-6 h-6 text-black" />
            );
          const selected = activeCategory === category.slug ? "font-bold" : "";

          return category.slug !== "uncategorized" ? (
            <li key={category?.databaseId}>
              <Link href={`/blog/category/${category?.slug}/`}>
                <a className="flex justify-between items-center py-2 md:py-4 cursor-pointer">
                  <div className="flex items-center">
                    {categoryIcon}
                    <span className={`${selected} text-lg ml-4`}>
                      {category.name}
                    </span>
                  </div>
                  {activeCategory === category?.slug && (
                    <Check className="w-6 h-6 text-blue" />
                  )}
                </a>
              </Link>
            </li>
          ) : (
            ""
          );
        })}
        <li>
          <Link href="/blog/">
            <a className="flex justify-between items-center pt-2 pb-4 md:pt-4 md:pb-6 cursor-pointer">
              <div className="flex items-center">
                <Layers className="w-6 h-6 text-blue" />
                <span
                  className={`${activeCategory === "" ? "font-bold" : ""} ml-4`}
                >
                  All
                </span>
              </div>
              {activeCategory === "" && <Check className="w-6 h-6 text-blue" />}
            </a>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default BlogCategories;
