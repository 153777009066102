import { Field, FormikProps } from "formik";
import { IContactFormValues } from "../../molecules/ContactForm";

interface IFormField {
  formik: FormikProps<IContactFormValues>;
  fieldName: string;
  fieldType: string;
  fieldAs?: string;
  placeholder: string;
  fieldHeight: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any, Element>) => void;
  value: string;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function FormField({
  formik,
  fieldName,
  fieldType,
  fieldAs,
  placeholder,
  fieldHeight,
  onChange,
  onBlur,
  value,
}: IFormField): JSX.Element {
  return (
    <div className="flex flex-col flex-1 gap-2 w-full">
      <label htmlFor={fieldName} className="font-bold">
        {capitalizeFirstLetter(fieldName)}
      </label>
      <Field
        as={fieldAs}
        type={fieldType}
        id={fieldName}
        name={fieldName}
        placeholder={placeholder}
        className={`${fieldHeight} text-base p-4 rounded-md w-full bg-[#F3F5F7] placeholder-gray resize-none ${
          formik?.touched[fieldName] && formik.errors[fieldName]
            ? "outline-red-500"
            : ""
        }`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      <div className="text-red-500 h-5">
        {formik.touched[fieldName] && formik.errors[fieldName]
          ? formik.errors[fieldName]
          : null}
      </div>
    </div>
  );
}

export default FormField;
