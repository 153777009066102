import { useState } from "react";

export interface INewsletterCTAFormValues {
  email: string;
}

const useSubscribeToNewsletter = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [isError, setIsError] = useState(null);
  const [message, setMessage] = useState(null);

  const subscribeOnSubmit = async (
    { email }: INewsletterCTAFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const response = await fetch("/api/subscribe", {
      body: JSON.stringify({ email: email }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    const json = await response.json();
    const status = response.status;

    if (status !== 200) {
      if (json?.title === "Member Exists") {
        const message = "You are already subscribed to our newsletter.";
        setMessage(message);
        setIsError("Already subscribed!");
        setSubmitting(false);
      } else {
        const message = json?.title
          ? json.title
          : "Something went wrong. Please check whether your email address is correct.";
        setMessage(message);
        setIsError(message);
        setSubmitting(false);
      }

      return;
    }

    setIsSubscribed(true);
    setMessage("You have successfully subscribed to Crafted's newsletter.");
    setSubmitting(false);
  };

  return {
    isSubscribed,
    subscribeOnSubmit,
    message,
    isError,
  };
};

export default useSubscribeToNewsletter;
