import { client } from "client";
const useFeaturedPost = () => {
  const { usePosts } = client;
  return usePosts({
    where: { onlySticky: true },
    first: 1,
  })?.nodes[0];
};

export default useFeaturedPost;
