import HeadingBlock from "./HeadingBlock";
import ParagraphBlock from "./ParagraphBlock";
import HTMLRenderedBlock from "./HTMLRenderedBlock";
import { GenericDivider, LocationMarker, Hero } from "components";

export default function Block({ block, data }) {
  const { attributesJSON, name } = block;

  const attributes = attributesJSON
    ? JSON.parse(attributesJSON.replace(/\\"/g, "'"))
    : null;

  switch (name) {
    case "core/heading":
      return <HeadingBlock {...attributes} />;
    case "core/paragraph":
      return <ParagraphBlock {...attributes} />;
    case "core/separator":
      return <GenericDivider className="my-10" />;
    case "core/spacer":
      return <></>;
    case "genesis-custom-blocks/job-location-marker":
      return (
        <LocationMarker location={data?.jobACF?.location} className="mt-5" />
      );
    case "genesis-custom-blocks/hero":
      return <Hero {...attributes} />;

    default:
      return <HTMLRenderedBlock {...block} />;
  }
}
