import React from "react";
import type { Post } from "client";
import Image from "next/image";
import Link from "next/link";

interface Props {
  post: Post | undefined;
}

function FeaturedPost({ post }: Props): JSX.Element {
  const url = post?.featuredImage?.node?.mediaItemUrl;
  const width = post?.featuredImage?.node?.mediaDetails?.width;
  const height = post?.featuredImage?.node?.mediaDetails?.height;
  const alt = post?.featuredImage?.node?.altText;
  const firstCategory = post?.categories()?.nodes[0]?.name;
  return (
    <section
      id="featured-post"
      className="relative flex flex-col justify-center items-center w-full mt-20 mx-auto"
    >
      {url && (
        <div className="absolute content-container top-0 bottom-0 object-cover h-full w-full xl:w-2/3">
          <Image
            className="absolute inset-0 object-cover w-full h-full"
            alt={alt}
            src={url}
            layout="fill"
          />
        </div>
      )}
      <div className="overlay absolute inset-0 w-full h-full"></div>
      <div className="content-container flex justify-start pt-32 pb-8 md:py-20 px-6 md:px-16 lg:px-20 xl:px-0 w-full z-10">
        <div className="flex flex-col justify-start items-start w-full md:w-2/3 lg:w-1/2">
          <div className="font-body font-bold text-black bg-green-light rounded-md px-2 py-1 mb-3 md:mb-4">
            {firstCategory}
          </div>
          <div className="h3 text-white mb-3 md:mb-4 z-10">{post?.title()}</div>
          <div
            className="text-base md:text-lg text-white font-body mb-6 md:mb-8"
            dangerouslySetInnerHTML={{ __html: post?.excerpt() }}
          />
          <Link href={`/blog/${post?.slug}/`}>
            <a
              href={`/blog/${post?.slug}/`}
              className="block text-white text-sm font-body font-bold border-2 border-white rounded-md px-3 py-2 w-full md:w-auto truncate hover:border-blue"
            >
              Read More - {post?.title()}
            </a>
          </Link>
        </div>
      </div>
      <div className="flex justify-start items-start w-full z-10">
        <div className="bg-blue w-1/4 h-1.5"></div>
        <div className="bg-green w-1/4 h-1.5"></div>
        <div className="bg-red w-1/4 h-1.5"></div>
        <div className="bg-yellow w-1/4 h-1.5"></div>
      </div>
      <style jsx>{`
        .overlay {
          position: absolute;
          top: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(39, 39, 39, 1) 0%,
            rgba(39, 39, 39, 1) 60%,
            rgba(39, 39, 39, 0.6) 85%,
            rgba(39, 39, 39, 1) 100%
          );
        }
        @media screen and (min-width: 1200px) {
          .overlay {
            position: absolute;
            top: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(39, 39, 39, 1) 40%,
              rgba(39, 39, 39, 0.9) 50%,
              rgba(39, 39, 39, 0.8) 60%,
              rgba(39, 39, 39, 1) 70%,
              rgba(39, 39, 39, 1) 80%,
              rgba(39, 39, 39, 1) 100%
            );
          }
        }
        @media screen and (min-width: 1640px, max-width: 1200px) {
          .overlay {
            position: absolute;
            top: 0;
            z-index: 5;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(39, 39, 39, 1) 40%,
              rgba(39, 39, 39, 0.9) 45%,
              rgba(39, 39, 39, 0.8) 50%,
              rgba(39, 39, 39, 0.9) 55%,
              rgba(39, 39, 39, 1) 60%
            );
          }
        }
      `}</style>
    </section>
  );
}

export default FeaturedPost;
