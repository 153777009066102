import Image from "next/image";
import { Work as WorkType } from "client";

interface WorkProps {
  work: WorkType;
  showTitle: boolean;
  className?: string;
}

function LargeWorkCard({ work, showTitle = true, className }: WorkProps) {
  const title = work.title();
  const image = {
    url: work?.featuredImage?.node?.mediaItemUrl,
    width: work?.featuredImage?.node?.mediaDetails?.width,
    height: work?.featuredImage?.node?.mediaDetails?.height,
    alt: work?.featuredImage?.node?.altText,
  };
  const problem = work?.workACF?.problem;
  const solution = work?.workACF?.solution;
  const color = work?.workACF?.color ? work?.workACF?.color : "yellow";
  return (
    <>
      {showTitle && (
        <h3 className="h3 self-start text-4xl relative z-10 ml-2 -mb-24 sm:mb-0 lg:hidden">
          {title}
        </h3>
      )}

      <div
        className={`relative flex flex-col md:flex-row justify-end md:justify-start items-center w-[100vw] md:max-w-[100%] h-[80vh] md:h-[564px] bg-${color}-light md:rounded-[20px] shadow-[0_0_30px_0_rgba(105,105,105,0.2)] my-32 p-2 md:p-7 sm:mt-10 sm:mb-20 ${className}`}
      >
        <div className="flex flex-col w-full">
          {showTitle && (
            <h3 className="h3 text-4xl md:pl-0 relative z-10 mb-24 md:mb-0 hidden lg:block">
              {title}
            </h3>
          )}
          <ul className="flex flex-col gap-2 md:gap-5 mt-6">
            <li className="customBullet bg-white md:w-[500px] h-max rounded-md py-5 px-12 shadow-[0_0_30px_0_rgba(105,105,105,0.2)] relative z-10">
              <div className="flex flex-col justify-between items-start">
                <h4 className="text-lg md:text-xl mb-4">Problem</h4>
                <p className="text-[#696969] text-sm">{problem}</p>
              </div>
            </li>
            <li className="customBullet bg-white md:w-[500px] h-max rounded-md py-5 px-12 shadow-[0_0_30px_0_rgba(105,105,105,0.2)] relative z-10">
              <div className="flex flex-col justify-between items-start">
                <h4 className="text-lg md:text-xl mb-4">Solution</h4>
                <p className="text-[#696969] text-sm">{solution}</p>
              </div>
            </li>
            <style jsx>{`
              li.customBullet:before {
                content: "";
                position: absolute;
                top: 28px;
                left: 20px;
                height: 10px;
                width: 10px;
                background-color: var(--color-${color}-light);
                border-radius: 2px;
              }
            `}</style>
          </ul>
        </div>
        {image?.url && (
          <div className="absolute -top-10 md:top-auto md:bottom-0 right-0 md:right-0 md:rounded-[20px] w-[110%] md:w-[58%]">
            <Image
              layout="responsive"
              src={image.url}
              alt={image.alt}
              width={image.width}
              height={image.height}
              className="object-contain rounded-[20px]"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default LargeWorkCard;
