import { Formik, FormikHelpers } from "formik";
import { FormField } from "components";
import { useState } from "react";
import * as Yup from "yup";
import { Send } from "react-feather";
import NewsletterLoading from "components/atoms/svgs/NewsletterLoading";

export interface IContactFormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function ContactForm(): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const submitForm = (
    { name, email, phone, message }: IContactFormValues,
    setSubmitting: (arg: boolean) => void
  ) => {
    const payload = {
      input_1: name,
      input_3: email,
      input_4: phone,
      input_5: message,
    };

    const url = `/api/contact`;
    const data = JSON.stringify(payload);
    var formpost = fetch(url, {
      method: "POST",
      body: data, // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setConfirmationMessage(response.confirmation_message);
        setIsSubmitted(true);
        setSubmitting(false);
      });
    return formpost;
  };

  return (
    <div className={`flex-1 ${isSubmitted ? "self-start" : ""}`}>
      {!isSubmitted ? (
        <Formik
          initialValues={{ name: "", email: "", phone: "", message: "" }}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(15, "Must be 15 characters or less.")
              .required("Required"),
            email: Yup.string()
              .email("Invalid email address.")
              .required("Required"),
            phone: Yup.string()
              .matches(phoneRegExp, "Invalid phone number.")
              .required("Required"),
            message: Yup.string()
              .min(10, "Must be 10 characters or more.")
              .required("Required"),
          })}
          onSubmit={(
            values: IContactFormValues,
            { setSubmitting }: FormikHelpers<IContactFormValues>
          ) => {
            submitForm(values, setSubmitting);
            setSubmitting(true);
          }}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-3 text-sm w-full"
            >
              <FormField
                formik={formik}
                fieldName="name"
                fieldType="text"
                placeholder="Enter Name"
                fieldHeight="h-12"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <FormField
                formik={formik}
                fieldName="email"
                fieldType="text"
                placeholder="Enter Email"
                fieldHeight="h-12"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormField
                formik={formik}
                fieldName="phone"
                fieldType="text"
                placeholder="Enter Phone"
                fieldHeight="h-12"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              <FormField
                fieldAs="textarea"
                formik={formik}
                fieldName="message"
                fieldType="text"
                placeholder="Enter Message"
                fieldHeight="h-36"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              <button
                type="submit"
                className="flex justify-center items-center w-36 h-10 bg-black text-white rounded-md font-bold text-sm cursor-pointer disabled:cursor-not-allowed disabled:bg-slate-500 disabled:text-slate-100"
                disabled={formik.isSubmitting}
              >
                Send message
                {formik.isSubmitting && formik.isSubmitting ? (
                  <div className="bg-inherit w-5 h-5 ml-1">
                    <NewsletterLoading />
                  </div>
                ) : (
                  <Send className="w-5 h-5 ml-1 mt-0.5" />
                )}
              </button>
            </form>
          )}
        </Formik>
      ) : null}
      {isSubmitted ? (
        <div dangerouslySetInnerHTML={{ __html: confirmationMessage }} />
      ) : null}
    </div>
  );
}

export default ContactForm;
