import parseHtml from "lib/parser";
import { createElement } from "react";

function getClassName(align) {
  if (align === "center" || align === "right") {
    return `text-${align}`;
  }

  return `text-left`;
}

export default function HeadingBlock(props) {
  const { textAlign, content, level } = props;
  const headingLevel = "h" + level;
  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    createElement(headingLevel, props);

  return (
    <Heading className={getClassName(textAlign)}>{parseHtml(content)}</Heading>
  );
}
