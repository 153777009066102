import React from "react";
import TeamMember from "../atoms/cards/TeamMember.card";

import { Team_member as TeamMemberType } from "client";

interface TeamMembersProps {
  teamMembers: TeamMemberType[];
}

function TeamMembersGrid({ teamMembers }: TeamMembersProps): JSX.Element {
  return (
    <section className="section-container w-full mx-auto my-16">
      <div className="content-container px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
        <h3 className="text-4xl text-black mb-12">Team</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8">
          {teamMembers &&
            teamMembers.map((teamMember) => (
              <div
                key={teamMember?.id}
                className="col-span-1 mb-12 odd:pr-16 even:pl-16 md:odd:pr-0 md:even:pl-0 md:even:pt-8"
              >
                <TeamMember teamMember={teamMember} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default TeamMembersGrid;
