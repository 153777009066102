import { client, MenuLocationEnum } from "client";
const useMenu = (location = "PRIMARY") => {
  const { useQuery } = client;
  const { menuItems } = useQuery();
  return menuItems({
    where: { location: MenuLocationEnum[location] },
  }).nodes;
};

export default useMenu;
