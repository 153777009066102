import { useRef } from "react";
import Image from "next/image";
import { Page_Features_features } from "client";
import { useInView } from "framer-motion";

interface IAlternatingContentProps {
  features: Page_Features_features[];
}

function AlternatingContent({
  features,
}: IAlternatingContentProps): JSX.Element {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  const isInView3 = useInView(ref3, { once: true });
  return (
    <section className="w-full mx-auto mt-20 overflow-hidden">
      {features &&
        features.map((row, i) => {
          const image = {
            url: row?.image?.mediaItemUrl,
            width: row?.image?.mediaDetails.width,
            height: row?.image?.mediaDetails.height,
            altText: row?.image?.altText,
          };
          const heading = row?.heading;
          const color = row?.color;
          let ref;
          let isInView;
          if (i == 0) {
            ref = ref1;
            isInView = isInView1;
          } else if (i == 1) {
            ref = ref2;
            isInView = isInView2;
          } else if (i == 2) {
            ref = ref3;
            isInView = isInView3;
          }
          return (
            <div
              key={heading}
              ref={ref}
              className={
                isInView
                  ? "content-container flex flex-col-reverse lg:flex-row even:lg:flex-row-reverse lg:justify-between lg:items-center lg:gap-x-12 xl:gap-x-20 px-6 md:px-16 lg:px-20 xl:px-0 mx-auto my-40 md:my-72 transition-transform duration-1000 ease-in-out translate-x-0"
                  : "content-container flex flex-col-reverse lg:flex-row even:lg:flex-row-reverse lg:justify-between lg:items-center lg:gap-x-12 xl:gap-x-20 px-6 md:px-16 lg:px-20 xl:px-0 mx-auto my-40 md:my-72 transition-transform duration-1000 ease-in-out translate-x-80 even:-translate-x-80 md:translate-x-[40rem] md:even:-translate-x-[40rem] xl:translate-x-[70rem] even:xl:-translate-x-[70rem]"
              }
            >
              <div className="flex flex-col justify-start items-center lg:items-start lg:w-1/2 mt-8">
                <div className="flex flex-col justify-start items-start mb-4">
                  <div className="h2 text-black text-center">{row.heading}</div>
                </div>

                <ul className="relative flex flex-col justify-start items-start list-none">
                  {row?.bullettPoints &&
                    row.bullettPoints.map((bullet) => {
                      return (
                        <li
                          key={bullet.eachBullet}
                          className="customBullet text-lg text-black ml-8 pb-6"
                        >
                          {bullet.eachBullet}
                          <style jsx>{`
                            li.customBullet:before {
                              content: "";
                              position: absolute;
                              left: 0;
                              height: 10px;
                              width: 10px;
                              background-color: ${color};
                              border-radius: 2px;
                              margin-top: 7px;
                            }
                          `}</style>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {image.url && image.width && image.height && (
                <div className="mx-auto w-full h-full px-0 sm:px-10 md:px-28 lg:px-0 lg:w-1/2">
                  <Image
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    alt={image.altText}
                    layout="responsive"
                  />
                </div>
              )}
            </div>
          );
        })}
    </section>
  );
}

export default AlternatingContent;
