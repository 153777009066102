import Image from 'next/image';
import { Team_member as TeamMemberType } from 'client';

interface TeamMemberProps {
  teamMember: TeamMemberType;
}

function TeamMemberCard({ teamMember }: TeamMemberProps) {
  const name = teamMember.title();
  const title = teamMember.team.title;
  const image = {
    url: teamMember?.featuredImage?.node?.mediaItemUrl,
    width: teamMember?.featuredImage?.node?.mediaDetails?.width,
    height: teamMember?.featuredImage?.node?.mediaDetails?.height,
    alt: teamMember?.featuredImage?.node?.altText,
  };
  return (
    <div className="flex flex-col justify-start items-start w-full">
      {image?.url && (
        <div className="relative rounded-lg overflow-hidden h-80 w-full mb-4">
          <Image
            layout="responsive"
            src={image.url}
            alt={image.alt}
            width={image.width}
            height={image.height}
            className="absolute top-0 left-0 h-full w-full object-cover"
          />
        </div>
      )}
      <div className="h6 mb-4">{name}</div>
      <div className="text-sm text-gray">{title}</div>
    </div>
  );
}

export default TeamMemberCard;
