import clsx from "clsx";

interface IMobileMenuProps {
  popoverOpen: boolean;
}

const MobileMenuIcon = ({ popoverOpen }: IMobileMenuProps) => {
  const mobile_nav_class = clsx("mobile-menu relative lg:hidden", {
    "mobile-active": popoverOpen,
  });
  return (
    <div className={mobile_nav_class}>
      <div className="absolute blob-bg " />
      <div className="menu-button cursor-pointer">
        <span className={popoverOpen ? "on" : ""}>
          <svg viewBox="0 0 120 120">
            <g>
              <path
                className="line top"
                d="M35,35h50c14.1,0,50.6,13,20.5,53.5s-121.9,21.6-94.4-40.3S111.6,8.4,85,35L35,85"
              />
              <path
                className="line bottom"
                d="M35,85h50c14.1,0,50.6-13,20.5-53.5S-16.4,9.9,11.1,71.8S111.6,111.6,85,85L35,35"
              />
              <line className="line cross" x1="35" y1="60" x2="85" y2="60" />
            </g>
          </svg>
        </span>
      </div>
      <style jsx>{`
        .menu-button .line.top {
          stroke: #6484fa;
        }
        .menu-button .line.bottom {
          stroke: #f9a601;
        }
        .menu-button .line.cross {
          stroke: #73bfb8;
        }

        .menu-button .on .line.top,
        .menu-button .on .line.bottom,
        .menu-button .on .line.cross {
          stroke: #e48c9e;
        }

        .menu-button svg {
          display: block;
          width: 50px;
          height: 50px;
        }
        .menu-button {
          border: none;
          background: transparent;
          outline: none;
          cursor: pointer;
          padding: 0;
        }
        .line {
          fill: none;
          stroke: #71818d;
          stroke-width: 6px;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        /* Hamburger */
        .line.top,
        .line.bottom {
          stroke-dasharray: 50px 600px;
          stroke-dashoffset: 0px;
        }

        .line.cross {
          stroke-dasharray: 50px 60px;
          stroke-dashoffset: 0px;
        }

        /* Cross */
        .on .line.top,
        .on .line.bottom {
          stroke-dasharray: 70.71px 600px;
          stroke-dashoffset: -392px;
        }

        .on .line.cross {
          stroke-dasharray: 50px 60px;
          stroke-dashoffset: 51px;
        }

        /* Timing */

        .line.cross {
          transition: 0.35s stroke-dasharray ease 0.35s,
            0.35s stroke-dashoffset ease 0.35s, 0.35s stroke ease 0s;
        }

        .line.top {
          transition: 0.5s stroke-dasharray ease 0s,
            0.5s stroke-dashoffset ease 0s, 0.35s stroke ease 0s;
        }

        .line.bottom {
          transition: 0.5s stroke-dasharray ease 0.35s,
            0.5s stroke-dashoffset ease 0.35s, 0.35s stroke ease 0s;
        }

        .on .line.cross {
          transition: 0.35s stroke-dasharray ease 0s,
            0.35s stroke-dashoffset ease 0s, 0.35s stroke ease 0s;
        }

        .mobile-active {
          z-index: 40;
        }

        .mobile-active .blob {
          z-index: 1;
          transform: translate(25%, -30%);
          transition: transform 0.45s cubic-bezier(1, 0, 0, 1);
        }

        .mobile-active .blob-bg {
          background: #f3f5f7;
          transform: translateY(0%);
        }

        .mobile-active .menu-button {
          position: relative;
          z-index: 50;
        }
      `}</style>
    </div>
  );
};

export default MobileMenuIcon;
