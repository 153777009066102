import parse, {
  DOMNode,
  HTMLReactParserOptions,
  domToReact,
} from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import Link from 'next/link';
import { PostObjectFieldFormatEnum } from 'client';

type html = (args?: { format?: PostObjectFieldFormatEnum }) => string;

export default function parseHtml(html: html) {
  if (typeof html !== 'string') return false;

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      const typedDomNode = domNode as unknown as Element;
      if (
        domNode instanceof Element &&
        domNode.attribs &&
        domNode.name === 'a'
      ) {
        const isInternalLink =
          domNode.name === 'a' &&
          domNode.attribs['data-internal-link'] === 'true';
        if (isInternalLink) {
          return (
            <Link href={domNode?.attribs?.href}>
              <a {...domNode.attribs}>
                {domToReact(domNode.children, options)}
              </a>
            </Link>
          );
        }
      }
    },
  };

  return parse(html, options);
}
