import React from "react";
import Link from "next/link";
import { MenuItem } from "client";
import Instagram from "../atoms/svgs/Instagram";
import LinkedIn from "../atoms/svgs/LinkedIn";
interface Props {
  copyrightHolder?: string;
  links?: MenuItem[] | undefined;
}

function Footer({
  copyrightHolder = "Crafted",
  links = undefined,
}: Props): JSX.Element {
  const year = new Date().getFullYear();
  const scrollToTop = function () {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="section-container flex flex-col justify-start items-center w-full mx-auto mt-20 mb-24">
      <div className="content-container w-full px-6 md:px-16 lg:px-20 xl:px-0">
        <div className="h2 text-black w-full mb-8">
          Let&apos;s work on this.
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-start w-full mb-8">
          <div className="flex flex-col justify-start items-start mb-10">
            <div className="h6 text-black mb-3">Email</div>
            <a
              href="mailto:hello@craftedup.com"
              className="text-lg text-black hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              hello@craftedup.com
            </a>
          </div>
          <div className="flex flex-col justify-start items-start mb-10">
            <div className="h6 text-black mb-3">Call</div>
            <a
              href="tel:317-653-1931"
              className="text-lg text-black hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              317-653-1931
            </a>
          </div>
          <div className="flex flex-col justify-start items-start mb-10">
            <div className="h6 text-black mb-3">HQ</div>
            <a
              href="https://goo.gl/maps/MnyjtUHkbVHrgnv26"
              className="text-lg text-black hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              755 Massachusetts Ave, <br className="sm:hidden" /> Indianapolis,
              IN 46204
            </a>
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="h6 text-black mb-2">Connect</div>
            <div className="flex justify-start items-center space-x-2">
              <a
                href="https://www.instagram.com/craftedup"
                className="p-1"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
                <span className="sr-only">Crafted Instagram</span>
              </a>

              <a
                href="https://www.linkedin.com/company/craftedup"
                className="p-1"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn />
                <span className="sr-only">Crafted LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-start lg:justify-between items-center md:items-start lg:items-center">
          <ul className="flex flex-wrap items-center mb-8 lg:mb-0">
            {links?.map((link) => {
              return (
                <li
                  key={`${link.label}$-menu`}
                  className="w-1/2 md:w-auto pl-1.5 md:pl-0 first:pl-0 first:pr-1.5 first:md:pr-0 last:w-full last:md:w-auto last:mt-4 last:md:mt-0 last:pl-0"
                >
                  <Link href={link.url ?? ""}>
                    {link.label === "Client Support" ? (
                      <a
                        className="block text-sm font-bold text-center text-black border-2 border-black rounded-md hover:text-slate-600 hover:border-slate-600 px-4 py-2 mr-5 w-full md:w-auto"
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link.label}
                      </a>
                    ) : (
                      <a
                        className="block text-sm font-bold text-center text-black border-2 border-black rounded-md hover:text-slate-600 hover:border-slate-600 px-4 py-2 mr-5 w-full md:w-auto"
                        href={link.url}
                        onClick={() => scrollToTop()}
                      >
                        {link.label}
                      </a>
                    )}
                  </Link>
                </li>
              );
            })}
          </ul>
          <p className="text-sm text-slate-500">
            {`© ${year} ${copyrightHolder}.`} <br />
            &#10084;&#65039; Indianapolis
          </p>
        </div>
      </div>
      <style jsx>
        {`
          .social-icon {
            width: 24px;
            height: 24px;
          }
        `}
      </style>
    </footer>
  );
}

export default Footer;
