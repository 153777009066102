import React, { useState } from "react";
import { CEOLink } from "components/index";
import { useInView } from "react-cool-inview";

interface HeroProps {
  heading: string;
  content: string;
  link: string;
}

function Hero({ heading, content, link }: HeroProps): JSX.Element {
  const [isHeroOutOfView, setIsHeroOutOfView] = useState(false);
  const { observe, inView } = useInView({
    threshold: 0.3,
    onEnter: () => {
      setIsHeroOutOfView(false);
    },
    onLeave: () => {
      setIsHeroOutOfView(true);
    },
  });

  return (
    <section
      className="flex flex-col justify-around items-center text-center mt-44 mb-20"
      ref={observe}
    >
      <div>
        <div className="flex flex-col justify-center items-center">
          {heading && (
            <h1 className="h1" dangerouslySetInnerHTML={{ __html: heading }} />
          )}
          {content && (
            <div
              className="text-xl my-7 sm:text-center sm:max-w-sm md:max-w-md lg:max-w-xl mx-5"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {link && (
            <div className="h-[70px] w-[271px]">
              {
                <CEOLink
                  className={`h-[70px] w-[271px] ${
                    !isHeroOutOfView || inView
                      ? "static"
                      : "fixed bottom-5 right-5 z-50"
                  }`}
                  link={link}
                />
              }
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Hero;
