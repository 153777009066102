import React from "react";

function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
    >
      <defs>
        <path
          id="a"
          d="M5.361 23.973H.396V7.986H5.36v15.987zM2.876 5.805C1.288 5.805 0 4.49 0 2.902a2.877 2.877 0 015.751 0c0 1.588-1.288 2.903-2.875 2.903zm21.07 18.168h-4.954v-7.782c0-1.855-.038-4.234-2.582-4.234-2.582 0-2.977 2.015-2.977 4.1v7.916h-4.96V7.986h4.762v2.18h.07c.662-1.256 2.282-2.581 4.698-2.581 5.024 0 5.949 3.309 5.949 7.606v8.782h-.005z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a"></use>
        </mask>
        <g fill="#272727" mask="url(#b)">
          <path d="M0 0h24v24H0z"></path>
        </g>
      </g>
    </svg>
  );
}

export default LinkedIn;
