import { MessageCircle } from "react-feather";
import Image from "next/image";

interface ICEOLinkProps {
  link: string;
  className: string;
}

function CEOLink({ className, link }: ICEOLinkProps) {
  return (
    <div className={className}>
      <div className="relative flex justify-start items-center h-[100%] w-[100%]">
        <a href={link} target="_blank" rel="noreferrer">
          <div className="flex justify-center items-center h-[50px] w-[236px] bg-[#6C84FA] rounded-md">
            <div className="flex justify-around items-center h-[100%] w-[75%] mr-8 pl-2">
              <MessageCircle className="mb-0.5" color="white" size={16} />
              <p className="font-bold text-white text-sm">
                Talk to our CEO Sean
              </p>
            </div>
          </div>
          <div className="absolute top-0 right-0 h-[70px] w-[70px]">
            <Image
              className="shadow-[0 0 30px 0 rgba(105,105,105,0.2)] rounded-full"
              src="/images/team/Sean_Hise_square.jpeg"
              width="70"
              height="70"
              layout="responsive"
              alt="Sean Hise"
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default CEOLink;
