import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="path-1"
          d="M12.005 5.893a6.118 6.118 0 00-6.128 6.128 6.118 6.118 0 006.128 6.128 6.118 6.118 0 006.128-6.128 6.118 6.118 0 00-6.128-6.128zm0 10.112a3.991 3.991 0 01-3.984-3.984 3.988 3.988 0 013.984-3.984 3.988 3.988 0 013.984 3.984 3.991 3.991 0 01-3.984 3.984zm7.808-10.362c0 .794-.64 1.429-1.429 1.429a1.43 1.43 0 111.43-1.43zm4.059 1.45c-.09-1.914-.528-3.61-1.93-5.008C20.543.688 18.847.251 16.932.155 14.96.043 9.045.043 7.072.155c-1.91.09-3.605.528-5.008 1.925S.229 5.173.134 7.088c-.113 1.973-.113 7.888 0 9.861.09 1.915.527 3.611 1.93 5.008 1.403 1.398 3.093 1.835 5.008 1.931 1.973.112 7.888.112 9.861 0 1.915-.09 3.611-.528 5.008-1.93 1.398-1.398 1.835-3.094 1.931-5.009.112-1.973.112-7.882 0-9.856zm-2.55 11.974a4.033 4.033 0 01-2.271 2.272c-1.574.624-5.307.48-7.046.48-1.738 0-5.477.138-7.045-.48a4.033 4.033 0 01-2.272-2.272c-.624-1.574-.48-5.307-.48-7.046 0-1.738-.139-5.477.48-7.045A4.033 4.033 0 014.96 2.704c1.573-.624 5.307-.48 7.045-.48 1.739 0 5.478-.139 7.046.48a4.033 4.033 0 012.272 2.272c.624 1.573.48 5.307.48 7.045 0 1.739.144 5.478-.48 7.046z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1063 -275)">
          <g transform="translate(150 227)">
            <g transform="translate(913)">
              <g transform="translate(0 48)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g fill="#272727" mask="url(#mask-2)">
                  <g>
                    <path d="M0 0H24V24H0z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Instagram;
