import { client } from "client";
const usePaginatedPosts = (POSTS_PER_PAGE = 6) => {
  return client.usePaginatedQuery(
    (query, args: { where: object; first: number; after?: any }, helpers) => {
      const posts = query.posts(args);
      const pageInfo = posts?.pageInfo;
      return {
        nodes: helpers.getArrayFields(
          posts?.nodes,
          "title",
          "slug",
          "date",
          "excerpt",
          "featuredImage"
        ),
        hasNextPage: pageInfo?.hasNextPage,
        endCursor: pageInfo?.endCursor,
      };
    },
    {
      initialArgs: {
        where: { onlySticky: false },
        first: POSTS_PER_PAGE,
      },
      merge({ data: { existing, incoming }, uniqBy }) {
        if (existing) {
          const nodesToMerge = uniqBy(
            [...(existing.nodes ?? []), ...(incoming.nodes ?? [])],
            (v) => v?.slug
          );
          return {
            ...incoming,
            nodes: nodesToMerge,
          };
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );
};

export default usePaginatedPosts;
