import { useScroll } from "hooks";
import clsx from "clsx";

interface IApplyNowBanner {
  jobTitle: string;
  jobLink: string;
}

function ApplyNowBanner({ jobTitle, jobLink }: IApplyNowBanner): JSX.Element {
  const { scrollY } = useScroll();

  return (
    <div
      className={clsx(
        scrollY > 70
          ? "apply__now--stuck sticky top-[18px] md:top-[26px] shadow-lg transform translate-y-[4.5rem] md:translate-y-20 transition-transform ease-out z-50 bg-[#F3F5F7]"
          : "w-full bg-[#F3F5F7] mb-1 mt-4"
      )}
    >
      <div className="flex justify-between items-center p-5 max-w-7xl mx-auto">
        <h4 className="text-xl">{jobTitle}</h4>
        <a
          href={jobLink}
          className="text-sm border-2 border-black px-2 sm:px-5 py-3 rounded-md font-bold flex justify-center items-center text-center"
          target="_blank"
          rel="noreferrer"
        >
          Apply Today
        </a>
      </div>
      <style jsx>{`
        .apply__now--stuck {
          top: 8px;
        }
      `}</style>
    </div>
  );
}

export default ApplyNowBanner;
