import { MapPin } from "react-feather";

interface ILocationMarkerProps {
  location: string;
  className?: string;
}

function LocationMarker({ location, className }: ILocationMarkerProps) {
  return (
    <div className={`text-xl text-black font-bold flex ${className}`}>
      <MapPin className="w-5 text-green mr-2" />
      {location}
    </div>
  );
}

export default LocationMarker;
