interface IBrandColorsDivider {
  className?: string;
}

function BrandColorsDivider({ className }: IBrandColorsDivider) {
  return (
    <div className={`flex justify-start items-start w-full z-10 ${className}`}>
      <div className="bg-blue w-1/4 h-1.5"></div>
      <div className="bg-green w-1/4 h-1.5"></div>
      <div className="bg-red w-1/4 h-1.5"></div>
      <div className="bg-yellow w-1/4 h-1.5"></div>
    </div>
  );
}

export default BrandColorsDivider;
