import React from "react";
import Link from "next/link";
import { MapPin, ExternalLink } from "react-feather";
import { Job as JobType } from "client";
import _find from "lodash/find";

interface JobListingsTableProps {
  jobs: JobType[];
}

function JobListingsTable({ jobs }: JobListingsTableProps): JSX.Element {
  return (
    <section className="section-container w-full mx-auto mb-24 lg:mb-32">
      <div id="jobs" className="py-16"></div>
      <div className="content-container px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
        <div className="h2 text-black w-full mb-12">Job Opportunities</div>
        <table className="table-auto border-b border-slate-300 w-full">
          <tbody>
            {jobs &&
              jobs.map((job) => {
                const id = job?.id;
                const title = job?.title();
                const description = job?.jobACF.description;
                const location = job?.jobACF?.location;
                const slug = job?.slug;
                return (
                  <tr
                    key={slug}
                    className="flex flex-col md:flex-row justify-between md:items-center border-t border-slate-300 w-full"
                  >
                    <td className="flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center md:w-3/5 lg:w-2/3 pt-6 pb-4 md:py-10">
                      <div className="h6 text-black whitespace-nowrap w-full lg:w-1/2 xl:w-2/5 mb-4 lg:mb-0">
                        {title}
                      </div>
                      <div className="flex justify-center text-sm text-black lg:w-1/2 xl:w-3/5">
                        {description}
                      </div>
                    </td>
                    <td className="text-sm text-black font-bold flex md:justify-center items-center">
                      <MapPin className="w-5 text-green mr-2" />
                      {location}
                    </td>
                    <td>
                      <Link href={`/jobs/${slug}/`}>
                        <a className="flex justify-start items-center text-sm text-black font-bold whitespace-nowrap hover:cursor-pointer py-6">
                          Learn More <ExternalLink className="w-5 ml-2" />
                        </a>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default JobListingsTable;
