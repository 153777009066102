import { useState, useEffect, useCallback } from "react";

const useScroll = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const onScroll = useCallback(() => {
    const { scrollY, scrollX } = window;
    setScrollY(scrollY);
    setScrollX(scrollX);
  }, []);

  useEffect(() => {
    //add eventlistener to window
    window.addEventListener("scroll", onScroll, { passive: true });
    // remove event on unmount to prevent a memory leak
    () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return { scrollY, scrollX };
};

export default useScroll;
