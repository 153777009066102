import { NextSeo } from "next-seo";
import { Post, Page, Service, Job, Specialty } from "client";
export interface AllPostTypeProps {
  page:
    | Post
    | Post["preview"]["node"]
    | Page
    | Page["preview"]["node"]
    | Service
    | Service["preview"]["node"]
    | Specialty
    | Specialty["preview"]["node"]
    | Job
    | Job["preview"]["node"]
    | null
    | undefined;
}

const useSEO = ({ page }: AllPostTypeProps) => {
  if (!page?.seo?.metaRobotsNoindex) return null;

const ogTitle = page?.seo?.opengraphTitle || page?.seo?.title || "UI that scales";

const ogDescription = page?.seo?.opengraphDescription || page?.seo?.metaDesc || "";
  const OGImages = [
    {
      url: `https://cdn.thumbsmith.com/v1/u/crafted/basic?title=${ogTitle}&description=${ogDescription}`,
      width: 1200,
      height: 630,
      alt: page?.seo?.title,
    },
  ];

  if (page?.seo?.opengraphImage?.sourceUrl()) {
    OGImages.push({
      url: page?.seo?.opengraphImage?.sourceUrl(),
      width: page?.seo?.opengraphImage?.mediaDetails?.width,
      height: page?.seo?.opengraphImage?.mediaDetails?.height,
      alt: page?.seo?.opengraphImage?.altText,
    });
  }
  console.log(page?.seo?.opengraphTitle || page?.seo?.title)
  return (
    <NextSeo
      title={page?.seo?.title}
      description={page?.seo?.metaDesc}
      canonical={page?.seo?.opengraphUrl}
      noindex={page?.seo?.metaRobotsNoindex !== "index"}
      nofollow={page?.seo?.metaRobotsNofollow !== "follow"}
      openGraph={{
        type: "website",
        locale: "en_US",
        url: page?.seo?.opengraphUrl,
        title: page?.seo?.opengraphTitle || page?.seo?.title,
        description: page?.seo?.opengraphDescription || page?.seo?.metaDesc,
        images: OGImages,
      }}
      twitter={{
        site: "@crafteduiux",
        cardType: "summary_large_image",
      }}
    />
  );
};

export default useSEO;
