import { Service as ServiceType } from "client";
import Image from "next/image";
import Link from "next/link";

interface ServiceProps {
  services: ServiceType[];
}

function ServicesListing({ services }: ServiceProps): JSX.Element {
  return (
    <section className="section-container w-full mx-auto mt-16 mb-24">
      <div className="content-container flex flex-col justify-start items-start px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
        <h3 className="h3 text-black">Services</h3>
        <div className="relative mt-8">
          <ul className="servicesList flex items-start justify-between flex-wrap lg:flex-nowrap">
            {/* {services.map((service) => {
              const id = service?.id;
              const title = service?.title();
              const description = service?.serviceACF?.description;
              const uri = service?.uri;
              const imgUrl = service?.featuredImage?.node?.mediaItemUrl;
              const width = service?.featuredImage?.node?.mediaDetails?.width;
              const height = service?.featuredImage?.node?.mediaDetails?.height;
              const alt = service?.featuredImage?.node?.altText;
              return(
              <li className="serviceItem mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-auto" key={id}>
                <div className="flex justify-start items-center">
                  {imgUrl && (
                    <div className="relative w-8 h-6 mr-4 serviceIcon">
                      <Image
                        className="relative w-full"
                        alt={alt}
                        src={imgUrl}
                        width={width}
                        height={height}
                        layout="fill"
                      />
                    </div>
                  )}
                  {uri && title && (
                    <Link href={uri}>
                      <a className="h5 text-black">{title}</a>
                    </Link>
                  )}
                </div>
                <p>{description}</p>
              </li>
              );
            })} */}
          </ul>
        </div>
      </div>
      <style jsx>{`
        .servicesList {
          margin: 0 -1rem;
        }
        .servicesList .serviceItem {
          padding: 0 1rem;
        }
        .serviceItem .serviceIcon {
          filter: invert(58%) sepia(23%) saturate(7045%) hue-rotate(207deg)
            brightness(98%) contrast(100%);
        }
      `}</style>
    </section>
  );
}

export default ServicesListing;
