import React from "react";
import type { Post } from "client";
import { MediaItemSizeEnum } from "client";
import Image from "next/image";
import Link from "next/link";
interface Props {
  post: Post | undefined;
}

function BlogPostCard({ post }: Props): JSX.Element {
  const image = {
    url: post?.featuredImage?.node.sourceUrl({
      size: MediaItemSizeEnum.LARGE,
    }),
    width: post?.featuredImage?.node?.mediaDetails?.width,
    height: post?.featuredImage?.node?.mediaDetails?.height,
    alt: post?.featuredImage?.node?.altText,
  };

  const title = post?.title();
  const slug = post?.slug;
  const excerpt = post?.excerpt();
  const categories = post?.categories().nodes;

  return (
    <section className="flex flex-col sm:flex-row justify-start items-center sm:gap-x-6 py-4 mb-4">
      {image.url && (
        <div className="blog-post-image-wrapper relative w-full h-56 sm:h-auto sm:w-2/5 md:w-52 md:h-52 sm:aspect-square rounded-md overflow-hidden mb-4 flex-2">
          <Link href={`/blog/${slug}/`}>
            <a
              href={`/blog/${slug}/`}
              className="h-full w-full block object-cover"
            >
              <Image
                className="absolute object-cover h-full w-full block"
                alt={image.alt}
                src={image.url}
                layout="fill"
              />
            </a>
          </Link>
        </div>
      )}
      <div className="flex flex-col justify-start items-start flex-1">
        <Link href={`/blog/${slug}/`}>
          <a
            href={`/blog/${slug}/`}
            className="h6 text-black mb-4 hover:underline"
          >
            {title}
          </a>
        </Link>
        <div
          dangerouslySetInnerHTML={{ __html: excerpt }}
          className="font-light text-sm text-gray"
        />
        <div className="flex justify-start items-center gap-x-2">
          {categories[0]?.name && (
            <div className="font-bold text-sm text-black bg-green-light rounded-md px-2 py-1 mt-4">
              {categories[0]?.name}
            </div>
          )}
          {categories[1]?.name && (
            <div className="font-bold text-sm text-black bg-yellow-light rounded-md px-2 py-1 mt-4">
              {categories[1]?.name}
            </div>
          )}
          {categories[2]?.name && (
            <div className="font-bold text-sm text-black bg-red rounded-md px-2 py-1 mt-4">
              {categories[2]?.name}
            </div>
          )}
          {categories[3]?.name && (
            <div className="font-bold text-sm text-black bg-blue rounded-md px-2 py-1 mt-4">
              {categories[3]?.name}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BlogPostCard;
