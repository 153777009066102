import Image from "next/image";
import { Work as WorkType } from "client";
import clsx from "clsx";

interface WorkProps {
  work: WorkType;
  color: string;
}

function SmallWorkCard({ work, color }: WorkProps) {
  const title = work.title();
  const image = {
    url: work?.featuredImage?.node?.mediaItemUrl,
    width: work?.featuredImage?.node?.mediaDetails?.width,
    height: work?.featuredImage?.node?.mediaDetails?.height,
    alt: work?.featuredImage?.node?.altText,
  };
  const description = work?.workACF?.description;
  // const color = work?.workACF?.color ? work?.workACF?.color : "blue";
  const link = work?.workACF?.link;

  return (
    <div className="flex flex-col justify-center items-start p-3">
      {image?.url && (
        <a href={link} target="_blank" rel="noreferrer">
          <div
            className={`group relative w-[95vw] flex justify-center items-center sm:w-[520px] h-[365px] rounded-[20px] shadow-[0_0_30px_0_rgba(105,105,105,0.2)] ${color}-light`}
          >
            {link && (
              <div className="opacity-0 group-hover:opacity-100 transition ease-in-out duration-300 py-3 px-6 bg-black text-white opacity-1 rounded-md z-10">
                View Website
              </div>
            )}
            <Image
              layout="fill"
              src={image.url}
              alt={image.alt}
              className={clsx(
                "absolute object-cover rounded-[20px] transition ease-in-out duration-300",
                { "group-hover:opacity-60": link }
              )}
            />
          </div>
        </a>
      )}
      <h3 className="mt-7">{title}</h3>
      <p className="max-w-[520px] leading-7 text-xl">{description}</p>
    </div>
  );
}

export default SmallWorkCard;
