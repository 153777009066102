import React from "react";
import { ChevronDown } from "react-feather";

function JumpLink(): JSX.Element {
  return (
    <section className="section-container w-full mx-auto mt-16 mb-24">
      <div className="content-container flex flex-col justify-start items-start px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
        <h1 className="h2 text-black">About</h1>
        <div className="relative mt-8">
          <li className="hiringBullet">
            <a
              href="#jobs"
              className="flex justify-start items-center ml-8 p-2"
            >
              <span className="h6 text-black">We&apos;re Hiring</span>
              <div className="flex justify-center items-center border-2 border-black rounded-md p-0.5 ml-4">
                <ChevronDown className="w-4" />
              </div>
            </a>
            <style jsx>{`
              li.hiringBullet {
                list-style-type: none;
              }
              li.hiringBullet:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0.5em;
                height: 12px;
                width: 12px;
                background-color: #73bfb8;
                border-radius: 100%;
                margin-top: 7px;
              }
            `}</style>
          </li>
        </div>
      </div>
    </section>
  );
}

export default JumpLink;
