import { Header, Footer } from "components";
import { useSEO } from "hooks";
/**
 * A Layout component
 */
export default function Layout({ children, headerLinks, footerLinks, page }) {
  const SEO = useSEO({ page });
  return (
    <>
      <Header links={headerLinks}></Header>
      {SEO}
      <main>{children}</main>
      <Footer links={footerLinks}></Footer>
    </>
  );
}
