/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import { Popover, Transition } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import clsx from "clsx";
import { MenuItem } from "client";
import { MobileMenuIcon } from "components";
import { BookOpenIcon } from "@heroicons/react/24/outline";

interface IHeaderProps {
  links?: MenuItem[] | undefined;
}

function Header({ links = undefined }: IHeaderProps): JSX.Element {
  const [isSticky, setSticky] = useState(false);
  const router = useRouter();

  const handleScroll = () => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 70) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={clsx(
        isSticky && router.pathname !== "/blog/[postSlug]"
          ? "header--stuck sticky -top-20 transform translate-y-20 transition-transform ease-out z-50"
          : ""
      )}
    >
      <Popover
        className={clsx(
          "relative bg-white",
          isSticky && router.pathname !== "/blog/[postSlug]" ? "shadow-lg" : ""
        )}
      >
        {({ open }) => {
          return (
            <>
              <div
                className="absolute inset-0 z-30 pointer-events-none"
                aria-hidden="true"
              />
              <div className="relative z-30">
                <div className="section-container mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-6 lg:px-8 md:justify-start md:space-x-10">
                  <div className="w-[150px] h-[58px] mobile-logo">
                    <span className="sr-only">Crafted</span>
                    <Link href="/">
                      <a className="border-none outline-none pt-9">
                        <Image
                          src="/images/logo.png"
                          width="300"
                          height="116"
                          alt="Crafted Logo"
                          layout="responsive"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 :outline-0">
                      <span className="sr-only">Open menu</span>
                      <MobileMenuIcon popoverOpen={open} />
                    </Popover.Button>
                  </div>
                  <div className="hidden md:flex-1 md:flex justify-end">
                    <ul className="flex items-center md:ml-12">
                      {links?.map((link) => {
                        const linkClasses = link.cssClasses.includes("button")
                          ? "ml-10 inline-flex items-center justify-center border border-transparent rounded-md shadow-sm text-sm font-display font-bold text-white bg-black hover:bg-slate-600 hover:cursor-pointer"
                          : link.cssClasses.includes("button-light")
                          ? "ml-10 inline-flex items-center justify-center border border-black rounded-md shadow-sm text-sm font-display font-bold text-black hover:bg-gray-100 hover:cursor-pointer"
                          : "text-sm font-display font-bold text-black hover:text-slate-600 ml-8";
                        const linkString =
                          typeof link?.url === "string"
                            ? link?.url.replace(/\//g, "")
                            : "";
                        const routerString = router?.pathname
                          ?.toString()
                          .replace(/\//g, "");

                        return (
                          <li key={`${link.url}$-menu`} className={linkClasses}>
                            <Link href={link.url ?? ""}>
                              <a
                                href={link.url}
                                className={
                                  routerString == linkString &&
                                  !routerString.includes("contact")
                                    ? "border-b-2 border-black pb-3 px-4 py-2 "
                                    : "px-4 py-2"
                                }
                              >
                                {link.cssClasses.includes("button-light") && (
                                  <BookOpenIcon className="float-left mr-2 w-5" />
                                )}{" "}
                                {link.label}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel className="absolute z-20 top-0 inset-x-0 transition transform origin-top-right md:hidden">
                  <div className="shadow-lg ring-black ring-opacity-5 bg-white">
                    <div
                      className={`py-6 px-5 bg-slate-100 ${
                        isSticky ? "mt-20" : "mt-24"
                      }`}
                    >
                      <ul className="flex flex-col">
                        {links &&
                          links?.map((link) => {
                            const isButton = link.cssClasses.includes("button");
                            const linkClasses = isButton
                              ? "text-center my-4 w-full flex items-center justify-center  border border-transparent rounded-md shadow-sm text-base font-display font-bold text-white bg-black hover:bg-slate-600"
                              : "text-left my-4 w-full rounded-md text-lg font-display font-bold text-gray-900 hover:text-gray-700";
                            return (
                              <div key={`${link.label}$-menu`}>
                                {isButton && (
                                  <div className="w-full border-t border-gray-300"></div>
                                )}
                                <li
                                  key={`${link.label}$-menu`}
                                  className={linkClasses}
                                >
                                  <Link href={link.url ?? ""}>
                                    <a
                                      href={link.url ?? ""}
                                      className="px-4 py-2 block w-full"
                                    >
                                      {link.label}
                                    </a>
                                  </Link>
                                </li>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
      <style jsx>
        {`
          .header--stuck {
            height: 106px;
          }
          .header--stuck .mobile-logo {
            width: 110px;
            height: 42px;
          }
        `}
      </style>
    </header>
  );
}

export default Header;
