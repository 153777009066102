import { PhoneCall, MapPin, Mail } from "react-feather";

function ContactInfo(): JSX.Element {
  return (
    <div className="flex flex-col flex-1 justify-center items-start">
      <h1 className="h2 mb-20">Contact Us</h1>
      <div>
        <div className="flex justify-start items-center gap-4 mb-3">
          <PhoneCall className="w-5 h-5 text-green" />

          <p className="text-xl">Call</p>
        </div>
        <a
          className="h3 hover:underline"
          href="tel:317-653-1931"
          target="_blank"
          rel="noreferrer"
        >
          317-653-1931
        </a>
      </div>
      <div className="mt-12">
        <div className="flex justify-start items-center gap-4 mb-3">
          <Mail className="w-5 h-5 text-red" />
          <p className="text-xl">Email</p>
        </div>
        <a
          className="h3 hover:underline"
          href="mailto:hello@craftedup.com"
          target="_blank"
          rel="noreferrer"
        >
          hello@craftedup.com
        </a>
      </div>
      <div className="mt-12">
        <div className="flex justify-start items-center gap-4 mb-3">
          <MapPin className="w-5 h-5 text-yellow" />
          <p className="text-xl">HQ</p>
        </div>
        <address className="h3 hover:underline not-italic max-w-md">
          <a
            href="href=https://goo.gl/maps/MnyjtUHkbVHrgnv26"
            target="_blank"
            rel="noreferrer"
          >
            755 Massachusetts Ave Indianapolis, IN 46204
          </a>
        </address>
      </div>
    </div>
  );
}

export default ContactInfo;
