import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { PopupButton } from "@typeform/embed-react";

const callsToAction = [{ name: "Watch demo", href: "#", icon: PlayCircleIcon }];

export default function SeriesBannerMobile({
  isSticky = false,
  chapters,
  post,
}) {
  return (
    <Popover
      className={clsx(
        "relative lg:hidden bg-white ",
        isSticky && "currently__reading--stuck sticky top-0 shadow-lg z-100"
      )}
    >
      <Popover.Button className=" p-4 w-full inline-flex justify-center items-center content-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span>Headless Guide</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {chapters.map((item) => (
                <div
                  key={item.slug}
                  className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <img src={item.icon} alt={item.shortTitle} />
                  </div>
                  <div>
                    <a href={item.link} className="font-semibold text-gray-900">
                      {item.shortTitle}
                      <span className="absolute inset-0" />
                    </a>
                    {/* <p className="mt-1 text-gray-600">{item.content}</p> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-blue-dark hover:bg-blue text-white">
              <a
                id="MFQ5J7EV"
                className="flex items-center justify-center gap-x-2.5 p-3 font-semibol"
                target="_blank"
                rel="noreferrer"
                href="https://craftedup.typeform.com/headless-survey"
              >
                Structured Content Readiness Quiz
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
