import React from "react";
import Image from "next/image";
import { Page_Aboutcta } from "client";

interface IAboutCTAProps {
  aboutCTA: Page_Aboutcta;
}

function AboutCTA({ aboutCTA }: IAboutCTAProps): JSX.Element {
  const image = {
    url: aboutCTA?.image?.mediaItemUrl,
    width: aboutCTA?.image?.mediaDetails.width,
    height: aboutCTA?.image?.mediaDetails.height,
    altText: aboutCTA?.image?.altText,
  };
  return (
    aboutCTA && (
      <section className="section-container relative flex flex-col lg:flex-row justify-start items-center w-full py-8 lg:py-24 xl:py-40 mx-auto mt-12 lg:mt-20">
        {image.url && image.width && image.height && (
          <div className="inline-block md:hidden w-full px-3">
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.altText}
              layout="responsive"
            />
          </div>
        )}
        <div className="section-container w-full mx-auto">
          <div className="content-container w-full px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
            <div className="flex flex-col justify-start items-start w-full md:w-5/12 mt-8">
              <div className="h4 mb-4">{aboutCTA.heading}</div>
              <ul className="relative flex flex-col justify-start items-start list-none">
                {aboutCTA.bullets.map((bullet) => {
                  return (
                    <li
                      key={bullet.bulletItem}
                      className="aboutCtaBullet text-lg text-black ml-8 pb-6"
                    >
                      {bullet.bulletItem}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <style jsx>{`
            li.aboutCtaBullet:before {
              content: "";
              position: absolute;
              left: 0;
              height: 10px;
              width: 10px;
              background-color: #6c84fa;
              border-radius: 2px;
              margin-top: 9px;
            }
          `}</style>
        </div>
        {image.url && image.width && image.height && (
          <div className="hidden md:inline-block absolute right-0 top-0 bottom-0 w-full md:w-1/2 px-4 md:px-0">
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.altText}
              className="absolute right-0 top-0 bottom-0 w-full object-contain"
            />
          </div>
        )}
      </section>
    )
  );
}

export default AboutCTA;
