// import { Specialty as SpecialtyType } from "client";
import Image from "next/image";
import Link from "next/link";

interface SpecialtyProps {
  specialties
  // specialties: SpecialtyType[];
}

function SpecialtiesListing({ specialties }: SpecialtyProps): JSX.Element {
  return (
    <section className="section-container w-full mx-auto mt-16 mb-24">
      <div className="content-container flex flex-col justify-start items-start px-6 md:px-16 lg:px-20 xl:px-0 mx-auto">
        <h3 className="h3 text-black">Specialties</h3>
        <div className="relative mt-8">
          <ul className="specialtiesList flex items-start justify-between flex-wrap lg:flex-nowrap">
            {specialties.map((specialty) => {
              const id = specialty?.id ? specialty?.id : specialty?.$on?.Specialty?.id;
              const title = specialty?.title ? specialty?.title : specialty?.$on?.Specialty?.title();
              const description = specialty?.specialtyACF?.description ? specialty?.specialtyACF?.description : specialty?.$on?.Specialty?.specialtyACF?.description;
              const uri = specialty?.uri ? specialty?.uri : specialty?.$on?.Specialty?.uri;
              const imgUrl = specialty?.featuredImage?.node?.mediaItemUrl ? specialty?.featuredImage?.node?.mediaItemUrl : specialty?.$on?.Specialty?.featuredImage?.node?.mediaItemUrl;
              const width = specialty?.featuredImage?.node?.mediaDetails?.width ? specialty?.featuredImage?.node?.mediaDetails?.width : specialty?.$on?.Specialty?.featuredImage?.node?.mediaDetails?.width;
              const height = specialty?.featuredImage?.node?.mediaDetails?.height ? specialty?.featuredImage?.node?.mediaDetails?.height : specialty?.$on?.Specialty?.featuredImage?.node?.mediaDetails?.height;
              const alt = specialty?.featuredImage?.node?.altText ? specialty?.featuredImage?.node?.altText : specialty?.$on?.Specialty?.featuredImage?.node?.altText;
              return(
              <li className="specialtyItem mb-6 lg:mb-0 w-full sm:w-1/2 lg:w-auto" key={id}>
                <div className="flex justify-start items-center">
                  {imgUrl && (
                    <div className="relative w-8 h-6 mr-4 specialtyIcon">
                      <Image
                        className="relative w-full"
                        alt={alt}
                        src={imgUrl}
                        width={width}
                        height={height}
                        layout="fill"
                      />
                    </div>
                  )}
                  {uri && title && (
                    <Link href={uri}>
                      <a className="h5 text-black">{title}</a>
                    </Link>
                  )}
                </div>
                <p>{description}</p>
              </li>
              );
            })}
          </ul>
        </div>
      </div>
      <style jsx>{`
        .specialtiesList {
          margin: 0 -1rem;
        }
        .specialtiesList .specialtyItem {
          padding: 0 1rem;
        }
        .specialtyItem .specialtyIcon {
          filter: invert(58%) sepia(23%) saturate(7045%) hue-rotate(207deg) brightness(98%) contrast(100%);
        }
      `}</style>
    </section>
  );
}

export default SpecialtiesListing;
