import React from "react";
import type { Post } from "client";
import Image from "next/image";
import Link from "next/link";

interface Props {
  post: Post | undefined;
}

function BlogFeaturedPost({ post }: Props): JSX.Element {
  const image = {
    url: post?.featuredImage?.node?.mediaItemUrl,
    width: post?.featuredImage?.node?.mediaDetails?.width,
    height: post?.featuredImage?.node?.mediaDetails?.height,
    alt: post?.featuredImage?.node?.altText,
  };

  const title = post?.title();
  const slug = post?.slug;
  const excerpt = post?.excerpt();
  const firstCategory = post?.categories().nodes[0];

  return (
    <div className="flex flex-col justify-start items-start w-full border-b border-slate-300 mb-8">
      <div className="hidden md:block h6 text-black mb-8">Featured</div>
      {image.url && (
        <div className="rounded-lg md:rounded-xl overflow-hidden">
          <Link href={`/blog/${slug}/`}>
            <a href={`/blog/${slug}/`} className="h-full w-full">
              <Image
                className="h-full w-full m-auto"
                alt={image.alt}
                height={image.height}
                width={image.width}
                src={image.url}
              />
            </a>
          </Link>
        </div>
      )}
      <Link href={`/blog/${slug}/`}>
        <a
          href={`/blog/${slug}/`}
          className="font-bold text-2xl md:text-4xl text-black hover:underline mt-8 mb-6"
        >
          {title}
        </a>
      </Link>
      <div
        dangerouslySetInnerHTML={{ __html: excerpt }}
        className="font-light text-base md:text-xl"
      />
      <div className="font-bold text-sm text-black bg-green-light rounded-md px-2 py-1 mt-4 mb-8">
        {firstCategory?.name}
      </div>
    </div>
  );
}

export default BlogFeaturedPost;
